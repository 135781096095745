<template>
  <div class="signSealOut">
    <div class="content">
      <template v-for="(item, index) in currentPositionList">
        <vue-draggable-resizable v-if="signList && signList.length" class-name="signPosition" :key="index"
          :x="item.x * ratio" :y="item.y * ratio + (item.page - 1) * wrapHeight" :w="getWidthAndHeight(item.width)"
          :h="getWidthAndHeight(item.height)" :draggable="hasPosition ? false : true"
          :resizable="type == 1 && item.sealEcloudId ? true : false"
          :handles="hasPosition ? ['br'] : ['tl', 'bl', 'tr', 'br']" :parent="true" @resizing="
            (left, top, width, height) =>
              onSealResizing({ left, top, width, height, index })
          " @resizestop="
            (left, top, width, height) =>
              onSealResizeStop({ left, top, width, height, index })
          " @dragging="(x, y) => drag({ x, y }, index, item)"
          @dragstop="(left, top) => onDragStop({ left, top, index, item })">
          <div class="seal-wrap">
            <div class="appoint" v-if="!item.sealEcloudId">签署位置</div>
            <template v-else>
              <i class="el-icon-delete" v-if="!item.signId" @click="deleteCurrent(index)"></i>
              <img :src="item.sealImg" alt="" @touchstart="stopMove" />
              <div v-if="item.dateFlag" class="time">
                <img @touchstart="stopMove" class="currenDateBase64" :src="textBecomeImg(currenDate, 14, '#000')"
                  alt="" />
                <i class="el-icon-delete" v-if="!hasPosition" @click="item.dateFlag = 0"></i>
              </div>
            </template>
          </div>
        </vue-draggable-resizable>
      </template>
      <ul class="file-img-list">
        <template v-if="fileInfo && fileInfo.fileKeyList">
          <li v-for="(item, index) in fileInfo.fileKeyList" :key="index" class="file-img-item">
            <img :src="item" alt="" />
            <div class="page-content" v-if="index < fileInfo.fileKeyList.length - 1">
              <SvgIcon type="icon_top_left" class="page_icon icon_top_left" />
              <SvgIcon type="icon_top_right" class="page_icon icon_top_right" />
              <SvgIcon type="icon_bottom_left" class="page_icon icon_bottom_left" />
              <SvgIcon type="icon_bottom_right" class="page_icon icon_bottom_right" />
            </div>
          </li>
        </template>
      </ul>
    </div>
    <div class="sealForm" v-if="sealShow">
      <div class="header">
        {{ fileInfo.type === 1 ? '放置签名' : '选择印章' }}
        <div class="icon" @click="sealShow = !sealShow">收起</div>
      </div>
      <div class="sealContent">
        <SvgIcon type="icon_left" class="btn-left" @click="handleRight" />
        <SvgIcon type="icon_left" class="btn-right" @click="handleLeft" />
        <!-- 签名 -->
        <div class="des">
          点击签名或拖拽至需要签署的位置
        </div>
        <ul class="signList" id="signList">
          <li class="nameImg-item add-user-sign" @click="addSignName" v-if="type == 1 && !addSignNameHide">
            <SvgIcon type="add" class="add-sign-icon" />
            <span class="add-sign-text">添加签名</span>
          </li>
          <li v-for="(item, index) in signList" :key="index" :class="
              sealData && sealData.sealEcloudId == item.sealEcloudId
                ? 'nameImg-item cur-seal'
                : 'nameImg-item'
            " @touchstart="touchStart($event, item)" @touchmove="touchMove($event)" @touchend="touchEnd($event)">
            <img class="" :src="item.sealImg" alt="" />
          </li>
        </ul>
      </div>
    </div>
    <div class="footer">
      <div class="btn-content">
        <div class="btn btn1" @click="handleAddBtn">
          放置签名
        </div>
        <div class="btn" @click="onConfirm">确认签署</div>
      </div>
    </div>
    <van-popup v-model="codeShow" get-container="body" round :close-on-click-overlay="false" position="bottom"
      @closed="codeShow = false">
      <div class="codeForm" :style="{ height: showKeyboard ? '85vh' : '300px' }">
        <div class="header">
          <div class="close" @click="codeShow = false">取消</div>
          短信验证
        </div>
        <div class="getCode" v-if="!signPwdTimerFlag">
          <div class="msg" @click.stop="getTelCode">获取验证码</div>
        </div>
        <div class="getCode" v-else>
          <div class="tag">验证码已发送到{{ tel }},请留意查收</div>
          <div class="msg" @click="getTelCode">重新发送（{{ num }}）</div>
        </div>
        <van-password-input :mask="false" :value="codeValue" :focused="showKeyboard" @focus="showKeyboard = true" />
        <!-- 数字键盘 -->
        <van-number-keyboard v-model="codeValue" :show="showKeyboard" @input="onInput" @delete="onDelete" />
      </div>
    </van-popup>
  </div>
</template>
<script>
  import { Toast } from 'vant'
  import moment from 'moment'
  import sassApi from '@/api/sass'
  import getSealRect from '@/utils/getSealRect'
  import { translateSealWidthAndHeight } from '@/utils/pxtorem'
  import InputPWD from '@/components/InputPWD.vue'
  import { mapState } from 'vuex'
  import textBecomeImg from '@/utils/textBecomeImg'

  export default {
    data() {
      return {
        // 客户传参
        telephone: '18612363312', // 电话
        contractNum: 'F9612DDDA5C669DC', // 未指定
        signPosition: [],
        // contractNum: '32B0D72761769B9B', // 指定
        // signPosition: [{ positionName: 'jiafang' }, { positionName: 'yifang' }],
        // contractNum: '7E365A06E87B3DB3', // 指定
        // signPosition: [{ positionName: 'jiafang' }, { positionName: 'yifang' }],
        timestamp: moment().format('YYYY-MM-DD HH:mm:ss'),
        appKey: 'yyzo37446dn03qb37x',
        v: '1.0',
        secret: '325f8bc85c18f179060cc9e8b82b3502',
        cardType: '0', // 0 身份证
        idCardNum: '110101199003077950', // 身份证号
        name: '段荣翔', // 姓名
        isFinish: 1, // 是否完成 0完成 1未完成
        callBack: '',
        signType: 1, // 是否直接打开手写板
        callbackParam: '',
        wxCallBack: '',
        smscode: 'true',
        addSignNameHide: false,
        dateImage: false,
        currenDate: moment().format('YYYY-MM-DD'),
        currenDateBase64: textBecomeImg(
          moment().format('YYYY-MM-DD'),
          14,
          '#000'
        ),
        sealShow: false, // 是否显示签名
        fileInfo: {}, // 文件信息
        signList: [], // 获取的签章/签名列表
        currentPositionList: [], // 待签署位置列表
        positionList: [],
        sealData: null, // 拖拽的签名id
        position: {
          x: 0,
          y: 0,
        },
        touchPosition: {
          x: 0,
          y: 0,
        },
        codeShow: false, // 是否显示验证码
        codeValue: '', // 验证码
        showKeyboard: false, // 键盘
        signPwdTimerFlag: false,
        num: 60,
        tel: '',
      }
    },
    computed: {
      type() {
        // 1签名 2签章
        return this.fileInfo?.type
      },
      // 容器宽度
      wrapWidth() {
        const imgWrap = document.getElementsByClassName('file-img-list')[0]
        const { width } = imgWrap.getBoundingClientRect()
        return width
      },
      // 签名比例
      ratio() {
        return this.wrapWidth / 595
      },
      // 容器高度
      wrapHeight() {
        return Math.ceil(this.ratio * 842)
      },
      // 是否指定位置
      hasPosition() {
        return (
          this.fileInfo?.currentPositionList &&
          this.fileInfo?.currentPositionList.length
        )
      },
      ...mapState({
        addSign: state => state.user.addSign,
        scrollHeight: state => state.user.scrollHeight,
      }),
    },
    created() {
      const {
        telephone, // 手机号
        contractNum, // 合同编号
        signPosition, // 指定位置 [{ positionName: 'jiafang' }, { positionName: 'yifang' }]
        timestamp, // 时间戳 "2021-01-18 14:56:12"
        appKey, // appKey "yyzo37446dn03qb37x"
        v, // 版本号 "1.0"
        secret, // 秘钥 "325f8bc85c18f179060cc9e8b82b3502"
        cardType, // 证件类型 0身份证
        idCardNum, // 证件号
        name, // 姓名
        isFinish, // 是否签署完成 0完成 1未完成
        callBack,
        signType,
        callbackParam,
        wxCallBack,
        allSign,
        smscode,
        addSignNameHide,
        dateImage,
      } = this.$route.query
      this.telephone = telephone
      this.contractNum = contractNum
      this.signPosition = signPosition
      this.timestamp = timestamp
      this.appKey = appKey
      this.v = v
      this.secret = secret
      this.cardType = cardType
      this.idCardNum = idCardNum
      this.name = name
      this.isFinish = isFinish
      this.callBack = callBack
      this.signType = signType
      this.callbackParam = callbackParam
      this.wxCallBack = wxCallBack
      this.allSign = allSign
      this.addSignNameHide = addSignNameHide
      this.dateImage = dateImage
      if (smscode) {
        this.smscode = smscode
      }
      if (this.wxCallBack) {
        window.sessionStorage.Refresh = true
      }
      this.getContractDetailInfo()
    },
    methods: {
      textBecomeImg,
      handleAddBtn() {
        if (this.signType) {
          this.addSignName()
          return
        }
        this.sealShow = !this.sealShow
      },
      stopMove(e) {
        e.preventDefault()
      },
      addSignName() {
        const height =
          window.pageYOffset ||
          document.documentElement.scrollTop ||
          document.body.scrollTop ||
          0
        this.$store.commit('setScrollHeight', height)
        this.$router.push({
          path: '/addSignNamePage',
          query: {
            timestamp: this.timestamp,
            appKey: this.appKey,
            v: this.v,
            secret: this.secret,
            telephone: this.telephone,
            signType: this.signType,
          },
        })
      },
      addNewContract() {
        this.toast = this.$toast.loading({
          message: '签署中...',
          forbidClick: true,
          loadingType: 'spinner',
          duration: 60000,
        })
        sassApi
          .addNewContract({
            contractNum: this.contractNum,
            apiSignerInfo: {
              mobilePhone: this.telephone,
              type: this.type,
              cardType: this.cardType,
              idCardNum: this.idCardNum,
              name: this.name,
            },
            apiSealParamList: this.positionList,
            isFinish: this.isFinish,
            timestamp: this.timestamp,
            appKey: this.appKey,
            v: this.v,
            secret: this.secret,
            callbackParam: this.callbackParam,
          })
          .then(res => {
            this.codeShow = false
            this.codeValue = ''
            this.$router.push({
              path: '/signFileSuccess',
              query: {
                callBack: this.callBack,
                appKey: this.appKey,
                contractNum: this.contractNum,
                telephone: this.telephone,
                secret: this.secret,
                timestamp: this.timestamp,
                v: this.v,
                wxCallBack: this.wxCallBack,
              },
            })
          })
          .catch(err => {
            console.log(err)
            this.codeValue = ''
          })
          .finally(() => {
            this.toast.clear()
          })
      },
      addContract() {
        this.toast = this.$toast.loading({
          message: '签署中...',
          forbidClick: true,
          loadingType: 'spinner',
          duration: 60000,
        })
        sassApi
          .addContract({
            contractNum: this.contractNum,
            apiSignerInfo: {
              mobilePhone: this.telephone,
              type: this.type,
              cardType: this.cardType,
              idCardNum: this.idCardNum,
              name: this.name,
            },
            apiSealParamList: this.positionList,
            dynamicCode: this.codeValue,
            isFinish: this.isFinish,
            timestamp: this.timestamp,
            appKey: this.appKey,
            v: this.v,
            secret: this.secret,
            callbackParam: this.callbackParam,
          })
          .then(res => {
            this.codeShow = false
            this.codeValue = ''
            this.$router.push({
              path: '/signFileSuccess',
              query: {
                callBack: this.callBack,
                appKey: this.appKey,
                contractNum: this.contractNum,
                telephone: this.telephone,
                secret: this.secret,
                timestamp: this.timestamp,
                v: this.v,
                wxCallBack: this.wxCallBack,
              },
            })
          })
          .catch(err => {
            console.log(err)
            this.codeValue = ''
          })
          .finally(() => {
            this.toast.clear()
          })
      },
      onInput(key) {
        this.codeValue = (this.codeValue + key).slice(0, 6)
        if (this.codeValue.length === 6) {
          // this.showKeyboard = false
          this.addContract()
        }
      },
      onDelete() {
        this.codeValue = this.codeValue.slice(0, this.codeValue.length - 1)
      },
      getTelCode() {
        const that = this
        that.signPwdTimerFlag = true
        this.signPwdTimer = setInterval(() => {
          that.num -= 1
          if (that.num <= 0) {
            clearInterval(that.signPwdTimer)
            that.signPwdTimerFlag = false
            that.num = 60
          }
        }, 1000)
        // 获取验证码
        sassApi
          .getTelCode({
            telephone: this.fileInfo.realMobile,
            contractNum: this.contractNum,
            timestamp: this.timestamp,
            appKey: this.appKey,
            v: this.v,
            secret: this.secret,
          })
          .then(res => {
            this.tel = res
          })
      },
      onConfirm() {
        if (this.currentPositionList.length === 0) {
          this.$toast('至少需要一个签章或签名')
        } else {
          const onePos = this.currentPositionList.some(e => !e.sealEcloudId)
          if (onePos) {
            this.$toast('还有剩余位置未盖章或签字')
            return
          }
          const positionList = JSON.parse(
            JSON.stringify(this.currentPositionList)
          )
          positionList.forEach(e => {
            e.y += e.height
            e.sealImg = undefined
            e.signId = e.sealEcloudId
          })
          this.positionList = positionList
          if (this.smscode === 'false') {
            this.addNewContract()
          } else {
            this.showKeyboard = true
            this.codeShow = true
          }
        }
      },
      translateSealWidthAndHeight,
      getWidthAndHeight(value) {
        if (!value) {
          return 106 * this.ratio
        }
        return this.translateSealWidthAndHeight(this.wrapWidth, value)
      },
      handleLeft() {
        let dom = null
        dom = document.getElementById('signList')
        const left = dom.scrollLeft
        this.setScrollLeft(dom, 'left')
      },
      handleRight() {
        let dom = null
        dom = document.getElementById('signList')
        const left = dom.scrollLeft
        this.setScrollLeft(dom, 'right')
      },
      // 横向滚动条滑动效果
      setScrollLeft(dom, value) {
        let num = 114
        const clock = setInterval(() => {
          if (num !== 0) {
            if (value === 'left') {
              dom.scrollLeft += 2
            } else {
              dom.scrollLeft -= 2
            }
            num -= 2
          } else {
            clearInterval(clock)
          }
        }, 1)
      },
      touchStart(e, item) {
        this.sealData = {
          sealEcloudId: item.sealEcloudId,
          sealImg: item.sealImg,
        }
        e.preventDefault()
        let el = e.target
        const element = e.targetTouches[0]
        if (
          e.target.getAttribute('class') !== 'nameImg-item' ||
          e.target.getAttribute('class') !== 'sealImg-item'
        ) {
          el = e.target.parentNode
        }
        this.li = el.cloneNode(true)
        this.touchPosition.x = element.clientX
        this.touchPosition.y = element.clientY
        this.position.x = el.getBoundingClientRect().left
        this.position.y = el.getBoundingClientRect().top
        this.left = el.getBoundingClientRect().left
        this.top = el.getBoundingClientRect().top
        this.li.style.cssText = `position: fixed;left: ${this.position.x}px; top: ${this.position.y}px; max-width: 98px;`
        document.body.appendChild(this.li)
        document.querySelector('#app').style.overflow = 'hidden'
      },
      touchMove(e) {
        const element = e.targetTouches[0]
        this.left = this.position.x + element.clientX - this.touchPosition.x
        this.top = this.position.y + element.clientY - this.touchPosition.y
        if (Math.abs(element.clientY - this.touchPosition.y) > 50) {
          this.isEnd = true
        }
        this.li.style.left = `${this.left}px`
        this.li.style.top = `${this.top}px`
        const height =
          window.pageYOffset ||
          document.documentElement.scrollTop ||
          document.body.scrollTop ||
          0
        this.top += height
        this.sealShow = false
      },
      touchEnd(e) {
        document.querySelector('#app').style.overflow = 'visible'
        if (!this.li) return
        document.body.removeChild(this.li)
        if (!this.isEnd) {
          // 点击触发
          this.setPosition()
        } else {
          this.onDrop(this.left, this.top)
        }
      },
      deleteCurrent(index) {
        if (this.hasPosition) {
          // 指定位置
          this.currentPositionList[index].sealEcloudId = null
          this.currentPositionList[index].sealImg = null
          this.currentPositionList[index].width = null
          this.currentPositionList[index].height = null
          this.currentPositionList[index].dateFlag = this.dateImage ? 0 : 1
          this.$forceUpdate()
        } else {
          this.currentPositionList.splice(index, 1)
        }
      },
      // 点击触发
      setPosition() {
        if (this.hasPosition) {
          this.onDrop(0, 0)
        } else {
          const height =
            window.pageYOffset ||
            document.documentElement.scrollTop ||
            document.body.scrollTop ||
            0
          const left = (this.wrapWidth - 93 * this.ratio) / 2
          this.onDrop(left, height + left)
        }
      },
      // 选择签名拖拽事件
      async onDrop(getX, getY, signId, index) {
        let sealData = null
        if (signId) {
          const item = this.signList.find(e => e.sealEcloudId === signId)
          if (!item) {
            return
          }
          sealData = {
            sealEcloudId: item.sealEcloudId,
            sealImg: item.sealImg,
          }
        }
        const { width, height } = await getSealRect(
          sealData ? sealData.sealImg : this.sealData.sealImg
        )

        const scale = this.ratio * 0.75
        // 边界校验
        if (getX < 0) {
          getX = 0
        }
        if (getX + width * scale > this.wrapWidth) {
          getX = this.wrapWidth - width * scale
        }
        if (getY < 0) {
          getY = 0
        }
        getX /= this.ratio
        this.isEnd = false
        // eslint-disable-next-line prefer-const
        let { page, y } = this.getCurrentPage(getY)
        if (y + height * scale + 20 > this.wrapHeight) {
          y = this.wrapHeight - height * scale - 20
        }
        if (this.hasPosition) {
          let temp = null
          if (signId) {
            temp = this.currentPositionList[index]
          } else {
            temp = this.currentPositionList.find(e => !e.sealEcloudId)
            index = this.currentPositionList.findIndex(e => !e.sealEcloudId)
          }
          if (!temp) {
            return
          }
          document.documentElement.scrollTop =
            (temp.page - 1) * this.wrapHeight +
            temp.y * this.ratio -
            height -
            20
          document.body.scrollTop =
            (temp.page - 1) * this.wrapHeight +
            temp.y * this.ratio -
            height -
            20

          if (this.allSign === '1') {
            this.currentPositionList.forEach(e => {
              e.width = width
              e.height = height
              e.sealEcloudId = this.sealData.sealEcloudId
              e.sealImg = this.sealData.sealImg
            })
          } else {
            console.log('落章')
            this.currentPositionList[index] = {
              appointFlag: 1,
              sealEcloudId: sealData
                ? sealData.sealEcloudId
                : this.sealData.sealEcloudId,
              sealImg: sealData ? sealData.sealImg : this.sealData.sealImg,
              width,
              height,
              x: temp.x,
              y: height >= 165 ?temp.y - (49 * scale) : temp.y,
              page: temp.page,
              dateFlag: this.dateImage ? 0 : 1,
              signId: temp.signId,
            }
          }
          this.$forceUpdate()
        } else {
          this.currentPositionList.push({
            appointFlag: 0,
            sealEcloudId: this.sealData.sealEcloudId,
            sealImg: this.sealData.sealImg,
            width,
            height,
            x: getX,
            y: y / this.ratio,
            page,
            dateFlag: this.dateImage ? 0 : 1,
          })
          this.$forceUpdate()
        }
      },
      // y坐标转换成页数
      getCurrentPage(y) {
        const disY = y
        if (disY < this.wrapHeight) {
          return {
            page: 1,
            y,
          }
        }
        return {
          page: Math.floor(disY / this.wrapHeight) + 1,
          y: disY % this.wrapHeight,
        }
      },
      // 拉伸签名
      onSealResizing({ left, top, width, height, index }) {
        const scale = this.ratio * 0.75
        const { page, y } = this.getCurrentPage(top)
        this.currentPositionList[index].width = width / scale
        this.currentPositionList[index].height = height / scale
        this.currentPositionList[index].x = left / this.ratio
        this.currentPositionList[index].y = y / this.ratio
        this.currentPositionList[index].page = page
      },
      onSealResizeStop({ left, top, width, height, index }) {
        const { page, y } = this.getCurrentPage(top)
        const scale = this.ratio * 0.75
        if (width <= 93 * scale) {
          width = 93 * scale
          this.currentPositionList[index].width = width / scale
        }
        if (height <= 53 * scale) {
          height = 53 * scale
          this.currentPositionList[index].height = height / scale
        }
        if (y + height + 20 >= this.wrapHeight) {
          this.currentPositionList[index].height =
            (this.wrapHeight - 20 - y) / scale
        }
        this.currentPositionList[index].changeFlag = 1
        this.$forceUpdate()
      },
      drag(position, index, item) {
        document.querySelector('body').style.overflow = 'hidden'
        this.onDragging(position, index, item)
      },
      onDragging(position, index, item) {
        const h = this.getWidthAndHeight(item.height)
        const positinCom = this.getCurrentPage(position.y)
        this.currentPositionList[index].x = position.x / this.ratio
        this.currentPositionList[index].y = positinCom.y / this.ratio
        this.currentPositionList[index].page = positinCom.page
      },
      onDragStop({ left, top, index, item }) {
        const scale = this.ratio * 0.75
        if (
          (top % this.wrapHeight) + item.height * scale + 20 >
          this.wrapHeight
        ) {
          top = this.wrapHeight - item.height * scale - 20
          this.currentPositionList[index].y = top / this.ratio
          this.$forceUpdate()
        }

        document.querySelector('body').style.overflow = 'visible'
      },
      getContractDetailInfo() {
        this.toast = Toast.loading({
          message: '获取中...',
          forbidClick: true,
          loadingType: 'spinner',
          duration: 60000,
        })
        sassApi
          .getContractDetailInfo({
            contractNum: this.contractNum,
            telephone: this.telephone,
            signPosition: this.signPosition,
            timestamp: this.timestamp,
            appKey: this.appKey,
            v: this.v,
            secret: this.secret,
          })
          .then(async res => {
            this.fileInfo = res
            if (res.currentPositionList) {
              this.currentPositionList = res.currentPositionList.map(e => {
                const obj = {
                  sealEcloudId: null,
                  sealImg: null,
                  width: null,
                  height: null,
                  x: e.x,
                  y: e.y,
                  page: e.page,
                  dateFlag: this.dateImage ? 0 : 1,
                  signId: e.signId,
                }
                return obj
              })
            } else {
              this.currentPositionList = []
            }

            this.getSignList()
          })
          .catch(err => {
            console.log(err.msg)
          })
          .finally(() => {
            this.toast.clear()
          })
      },
      getSignList() {
        sassApi
          .getSignList({
            telephone: this.telephone,
            signType: this.signType ? 1 : 0,
            timestamp: this.timestamp,
            appKey: this.appKey,
            v: this.v,
            secret: this.secret,
          })
          .then(res => {
            this.signList = res
            const images = document.querySelectorAll('.file-img-item img');

            const loadImagePromises = Array.from(images).map(img => {
                return new Promise((resolve, reject) => {
                    img.onload = () => resolve();
                    img.onerror = () => reject();

                    // 确保图片已经开始加载
                    if (img.complete) {
                        resolve();
                    }
                });
            });

            Promise.all(loadImagePromises).then(() => {
              console.log('文件加载完成')
              setTimeout(() => {
                this.setAutoSign()
              }, 1500);
              this.setDefaultSign()
            }).catch(error => {
              console.error('Error loading images:', error);
            });
            
          })
      },
      setAutoSign() {
        if (this.currentPositionList) {
          this.currentPositionList.forEach((e, i) => {
            if (e.signId) {
              this.onDrop(0, 0, e.signId, i)
            }
          })
        }
      },
      // 手写签名完成，自动添加签名
      setDefaultSign() {
        if (this.addSign) {
          this.$store.commit('setAddSign', false)
          const item = this.signList[1]
          this.sealData = {
            sealEcloudId: item.sealEcloudId,
            sealImg: item.sealImg,
          }
          if (!this.hasPosition) {
            document.documentElement.scrollTop = this.scrollHeight
            document.body.scrollTop = this.scrollHeight
            setTimeout(() => {
              this.setPosition()
            }, 1000)
          } else {
            this.setPosition()
          }
        }
      },
    },
  }
</script>
<style lang="less" scoped>
  .signSealOut {
    position: relative;
    padding-bottom: 56px;
    min-height: 100vh;
    box-sizing: border-box;
    overflow-x: hidden;
    .content {
      position: relative;
      background: #f4f5f6;
      .file-img-list {
        width: 100%;
        overflow: hidden;
        .file-img-item {
          position: relative;
          &:after {
            text-align: center;
            content: '签署位置不能指定在分页处';
            display: inline-block;
            // width: 100%;
            height: 20px;
            position: absolute;
            z-index: 1;
            left: 28px;
            bottom: -10px;
            right: 28px;
            font-size: 10px;
            font-family: PingFangSC, PingFangSC-Regular;
            font-weight: 400;
            color: #f15643;
            line-height: 20px;
            border: 1px dashed #f15643;
            box-sizing: border-box;
          }
          img {
            width: 100%;
          }
          .page-content {
            // padding: 28px;
            background: #fff;
            position: relative;
            .page_icon {
              font-size: 11px;
              position: absolute;
              z-index: 1;
            }
            .icon_top_left {
              left: 18px;
              top: -34px;
            }
            .icon_top_right {
              right: 18px;
              top: -34px;
            }
            .icon_bottom_left {
              left: 18px;
              bottom: -32px;
            }
            .icon_bottom_right {
              right: 18px;
              bottom: -32px;
            }
            .page-tip {
              font-size: 11px;
              height: 23px;
              color: #f15643;
              line-height: 16px;
              text-align: center;
              line-height: 22px;
              border: 1px dashed #f15643;
            }
          }
        }
        .file-img-item:nth-last-child(1) {
          &:after {
            display: none;
          }
        }
        .seal4-bg {
          &::before {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            content: '骑缝章签署区';
            width: 50px;
            height: 100%;
            color: #95befd;
            background: url('../../assets/imgs/sealManage/seal_bg1.svg');
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 10px;
            box-sizing: border-box;
            text-align: center;
            font-size: 28px;
          }
        }
      }
      .signPosition {
        position: absolute;
        z-index: 2 !important;
        background: transparent;
        border: 1px dashed #ffc000;
        font-size: 14px;
        font-family: PingFangSC, PingFangSC-Regular;
        font-weight: 400;
        text-align: center;
        color: #5f6368;
        line-height: 56px;
        display: flex;
        align-items: center;
        background: rgba(255, 249, 218, 0.6);
        .seal-wrap {
          display: flex;
          width: 100%;
          height: 100%;
          .appoint {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 14px;
            line-height: 16px;
          }
          img {
            width: 100%;
            height: 100%;
          }
        }
        .time {
          position: absolute;
          bottom: -20px;
          left: -1px;
          right: -1px;
          height: 20px;
          line-height: 20px;
          text-align: center;
          font-size: 10px;
          background: rgba(255, 249, 218, 0.6);
          border: 1px dashed #ffc000;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .el-icon-delete {
          padding: 3px;
          background: url('../../assets/imgs/ic_result_wrong@2x.png') no-repeat
            center;
          width: 12px;
          height: 12px;
          background-size: 12px 12px;
          position: absolute;
          top: 0px;
          right: 0px;
          z-index: 100;
          cursor: pointer;
        }
      }
    }
    .sealForm {
      position: fixed;
      -webkit-transform: translateZ(0);
      z-index: 103;
      bottom: 0;
      left: 0;
      width: 100%;
      background: #ffffff;
      box-shadow: 0px -6px 10px 0px rgba(230, 234, 237, 0.3);
      border-radius: 7px 7px 0px 0px;
      padding-bottom: 56px;
      .header {
        width: 100%;
        height: 59px;
        border: 1px solid #f5f5f5;
        font-size: 18px;
        font-family: PingFangSC, PingFangSC-Medium;
        font-weight: 500;
        text-align: center;
        color: #111a34;
        background: #f4f5f6;
        line-height: 59px;
        letter-spacing: 0px;
        position: relative;
        .icon {
          position: absolute;
          right: 16px;
          top: 20px;
          width: 36px;
          height: 20px;
          font-size: 14px;
          font-family: PingFangSC, PingFangSC-Regular;
          font-weight: 400;
          text-align: right;
          color: #666666;
          line-height: 20px;
          flex-shrink: 0;
        }
      }
      .sealContent {
        min-height: 154px;
        padding: 0 56px;
        position: relative;
        .btn-left {
          font-size: 26px;
          position: absolute;
          bottom: 55px;
          left: 16px;
          z-index: 10;
        }
        .btn-right {
          font-size: 26px;
          position: absolute;
          bottom: 55px;
          right: 16px;
          transform: rotate(180deg);
          -ms-transform: rotate(180deg);
          -moz-transform: rotate(180deg);
          -webkit-transform: rotate(180deg);
          -o-transform: rotate(180deg);
          z-index: 10;
        }
        .des {
          width: 100%;
          height: 20px;
          font-size: 14px;
          font-weight: 400;
          text-align: center;
          color: #677283;
          line-height: 20px;
          margin-top: 13px;
        }
        .signList {
          display: flex;
          align-items: center;
          flex-wrap: nowrap;
          padding-left: 16px;
          padding-bottom: 24px;
          padding-top: 20px;
          width: 100%;
          display: flex;
          flex-wrap: nowrap;
          // padding-left: 16px;
          overflow-x: auto;
          z-index: 20;
          .nameImg-item {
            position: relative;
            flex-shrink: 0;
            width: 98px;
            height: 98px;
            border-radius: 4px;
            background: #ffffff;
            border: 1px solid #e2e4ea;
            object-fit: cover;
            margin-right: 16px;
            display: flex;
            justify-content: center;
            align-items: center;
            img {
              width: 100%;
              height: 100%;
              -o-object-fit: scale-down;
              object-fit: scale-down;
            }
          }
          .add-user-sign {
            display: flex;
            flex-shrink: 0;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 98px;
            height: 98px;
            border-radius: 4px;
            border: 1px dashed #c1c4cd;
            font-size: 14px;

            .add-sign-icon {
              font-size: 14px;
            }

            .add-sign-text {
              margin-top: 10px;
              color: #969799;
            }
          }
        }
      }
    }

    .footer {
      position: fixed;
      -webkit-transform: translateZ(0);
      z-index: 103;
      bottom: 0;
      left: 0;
      width: 100%;
      background: #ffffff;
      box-shadow: 0px -6px 10px 0px rgba(230, 234, 237, 0.3);
      .btn-content {
        height: 56px;
        padding: 0 16px;
        display: flex;
        align-items: center;
      }
      .btn {
        flex: 1;
        height: 44px;
        border-radius: 4px;
        font-size: 16px;
        font-family: PingFangSC, PingFangSC-Medium;
        font-weight: 500;
        text-align: center;
        line-height: 44px;
        margin: 0 auto;
        // margin-bottom: 10px;
        color: #fff;
        background: #1676ff;
      }
      .btn1 {
        border: 1px solid #1676ff;
        color: #1676ff;
        background: #fff;
        margin-right: 12px;
      }
    }
  }
  .van-popup {
    .codeForm {
      .header {
        position: relative;
        height: 59px;
        border: 1px solid #f5f5f5;
        background: #f4f5f6;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 18px;
        font-family: PingFangSC, PingFangSC-Medium;
        font-weight: 500;
        text-align: center;
        color: #111a34;
        line-height: 18px;
        .close {
          font-size: 14px;
          font-family: PingFangSC, PingFangSC-Regular;
          font-weight: 400;
          text-align: left;
          color: #677283;
          line-height: 18px;
          position: absolute;
          top: 20px;
          left: 16px;
        }
      }
      /deep/ .van-password-input {
        margin-top: 20px;
      }
      .getCode {
        margin-top: 20px;
        .tag {
          font-size: 14px;
          font-family: PingFangSC, PingFangSC-Regular;
          font-weight: 400;
          color: #677283;
          text-align: center;
          line-height: 20px;
        }
        .msg {
          font-size: 14px;
          font-family: PingFangSC, PingFangSC-Regular;
          font-weight: 400;
          text-align: center;
          color: #1676ff;
          line-height: 20px;
        }
      }
    }
  }
</style>
